<template>
  <div class="supplierContactSettings">
    <div class="pvhFactory">
        <div class="">
            <div class="layTop d-flex">
                <div class="Title" style="margin-bottom: 10px;">{{ $t('Contact Setting') }}</div>

            </div>
            <div style="padding-top: 10px; text-align: right; align-self: flex-end;">
                    <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain @click="addBtn"> {{$t('New')}}</el-button>
            </div>
            <div style="margin-top: 10px;">
                <avue-crud class="pvhTable"
                           v-loading="loading"
                           v-model="obj"
                           :option="tableOpt"
                           :data="tableData"
                           :row-style="rowStyle"
                           :page.sync="page"
                           @current-change="currentChange">

                    <template slot="menuRight" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">

                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 10px;">
                                <el-button type="primary" size="small" icon="el-icon-plus" style="margin-left:10px;">
                                    {{ $t('ADD') }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button type="text" size="small" @click="disable(row)"
                                   :style="row.status == 0 ? 'color: #67c23a;' : 'color: #e6a23c'">
                            {{ $t(row.status != 0 ? 'ENABLE' : 'DISABLE') }}
                        </el-button>
                        <el-button type="text" size="small" @click="editBtn(row)">{{ $t('Edit') }}</el-button>
                    </template>

                </avue-crud>
            </div>
        </div>
    </div>

    <el-dialog
        :title="$t('CONTACTS')"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item :label="$t('Contact Name')" prop="userName">
            <!--            <el-input v-model="ruleForm.userName"></el-input>-->
            <el-select v-model="ruleForm.userName" :disabled="ruleForm.id" @change="userNameChange(ruleForm.userName)"
                       style="width: 100%;"
                       placeholder="请选择">
              <el-option style="width: 100%"
                         v-for="(v, index) in option"
                         :key="index"
                         :label="v.userName"
                         :value="v.userName">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Email')" prop="email">
            <el-input v-model="ruleForm.email"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Supplier')" prop="corporationId">
            <!--              <el-input v-model="ruleForm.supplier"></el-input>-->
            <el-select v-model="ruleForm.corporationId" placeholder="" style="width: 100%;" @change="chageSupplier">
              <el-option v-for="(v, index) in GetCurrCorporationData" :label="v.shortName" :value="v.id"
                         :key="index"></el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item :label="$t('SupplierYN')" prop="name">
                      <el-switch v-model="ruleForm.delivery"></el-switch>
                    </el-form-item>-->
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">OK</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  GetEmailContacts,
  DeleteEmailContact,
  GetCurrCorporation,
  PostEmailContact,
  PutEmailContact,
  GetCorpUserListByCurrentCorpId,
  DisableEmailContact
} from '@/api/maintain'

export default {
  name: "supplierContactSettings",
  data() {
    var checkEmail = (rule, value, callback) => {
      const mailReg = /[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9]+)*\.[a-zA-Z0-9]{2,6}/
      if (!value) {
        return callback(new Error(this.$t('PLEASE ENTER EMAIL ADDRESS')))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error(this.$t('Please enter the correct email format')))
        }
      }, 100)
    }
    return {
      loading: false,
      option: [],
      GetCurrCorporationData: [],
      ruleForm: {
        id: false,
        userId: '',
        userName: '',
        email: '',
        corporationId: '',
        isBrand: '',
        delivery: false
      },
      rules: {
        userName: [
          {required: true, message: this.$t('Please enter'), trigger: 'blur'},
        ],
        email: [
          {required: true, message: this.$t('Please enter'), trigger: 'blur'},
          { validator: checkEmail, trigger: 'blur', required: true }
        ],
        corporationId: [
          {required: true, message: this.$t('Please enter'), trigger: 'change'},
        ],
        isBrand: [
          {required: true, message: this.$t('Please enter'), trigger: 'change'},
        ],
      },
      dialogVisible: false,
      YesNoData: [
        {
          label: this.$t('Disable'),
          value: 1
        }, {
          label: this.$t('Available'),
          value: 0
        }

      ],
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
      },
      tableData: [],
      /*tableOpt: {
        page: true,
        addBtn: true,
        refreshBtn: false,
        emptyText: this.$t('No Data'),
        menu: true,
        menuTitle: this.$t('OPERATION'),
        menuWidth: 300,
        editBtn: false,
        editBtnText: this.$t('Edit'),
        editTitle: this.$t('Edit'),
        addTitle: this.$t('ADD'),
        delBtn: false,
        align: 'center',
        header: false,
        simplePage: false,
        labelWidth: "33%",
        expand: false,
        rowKey: 'Id',
        expandRowKeys: [],
        ListByCorpData: [],
        column: [
          {
            label: this.$t('Contact Name'),
            prop: 'userName',
          },
          {
            label: this.$t('Email'),
            prop: 'email',
          },
          {
            label: this.$t('Affiliation'),
            prop: 'corporationName',
          },
          {
            label: this.$t('SupplierYN'),
            prop: 'isBrand',
            type: 'select',
            dicData: this.YesNoData,
          }
        ]
      },*/
      obj: {},
    }
  },
  created() {
    this.list();
    GetCurrCorporation({}).then((respoonse) => {
      this.GetCurrCorporationData.push(respoonse.data)
    })
  },
  methods: {
    userNameChange(value) {
      this.option.forEach((f) => {
        if (f.userName == value) {
          this.ruleForm.email = f.email;
          this.ruleForm.userId = f.id;
        }
      })
    },
    submitForm(formName) {
      var ruleForm = this.ruleForm;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          if (ruleForm.id) {
            PutEmailContact({
              userId: ruleForm.userId,
              userName: ruleForm.userName,
              email: ruleForm.email,
              corporationId: ruleForm.corporationId,
              isBrand: ruleForm.isBrand ? 1 : 0,
              status: 0
            }).then((response) => {
              if (response.data.statusCode == 200 && !response.data.isError) {
                this.$message({message: this.$t('OperationSuccess'), type: "success"});
                this.page.total = 0;
                this.list();
              } else {
                this.$message({message: this.$t('OperationFailed'), type: "warning"});
              }
              this.resetForm('ruleForm')
            })
          } else {
            PostEmailContact({
              userId: ruleForm.userId,
              userName: ruleForm.userName,
              email: ruleForm.email,
              corporationId: ruleForm.corporationId,
              isBrand: ruleForm.isBrand ? 1 : 0
            }).then((response) => {
              if (response.data.statusCode == 200 && !response.data.isError) {
                this.$message({message: this.$t('OperationSuccess'), type: "success"});
                this.page.total = 0;
                this.list();
              } else {
                this.$message({message: this.$t('OperationFailed'), type: "warning"});
              }
              this.resetForm('ruleForm')
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.ruleForm = {
        id: '',
        userName: '',
        email: '',
        corporationId: '',
        isBrand: ''
      }
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },
    chageSupplier() {
    },
    delBtn(row) {
      DeleteEmailContact(row.userId).then(() => {
        this.list();
      })
    },
    disable(row) {
      DisableEmailContact({
        /*HttpPost  参数userId , status 0启用 1 禁用*/
        userId: row.userId,
        status: row.status == 0 ? 1 : 0
      }).then((response) => {
        if (response.data.statusCode == 200 && !response.data.isError) {
          this.$message({message: this.$t('OperationSuccess'), type: "success"});
          // this.page.total = 0;
          this.list();
        } else {
          this.$message({message: this.$t('OperationFailed'), type: "warning"});
        }
      })
    },
    editBtn(row) {
      var ruleForm = this.ruleForm;
      this.dialogVisible = true;
      this.ruleForm.id = true;
      ruleForm.userName = row.userName;
      ruleForm.email = row.email;
      ruleForm.userId = row.userId;
      ruleForm.corporationId = row.corporationId;
      ruleForm.isBrand = row.isBrand != 1 ? true : false;
      // this.GetCorpUserListByCurrentCorpId();
    },
    list() {
      this.loading = true;
      GetEmailContacts(this.page.currentPage, this.page.pageSize).then((response) => {
        this.loading = false;
        this.tableData = response.data.emailContacts;
        this.page.total = response.data.totalCount;
        this.page.pageSize = response.data.pageSize;
      })
    },
    addBtn() {
      this.dialogVisible = true;
      this.ruleForm.id = false;
      this.GetCorpUserListByCurrentCorpId();
    },
    GetCorpUserListByCurrentCorpId() {
      GetCorpUserListByCurrentCorpId({}).then((response) => {
        this.option = response.data
      })
    },
    rowStyle({rowIndex}) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
    },
    currentChange(val) {
      this.page.currentPage = val
      this.list()
      // this.getOperateLogList();
    },
    handleClose() {
      this.resetForm('ruleForm');
      this.dialogVisible = false;
    }
  },
  computed: {
    tableOpt() {
      return {
        page: true,
        addBtn: true,
        refreshBtn: false,
        emptyText: this.$t('No Data'),
        menu: true,
        menuTitle: this.$t('OPERATION'),
        menuWidth: 300,
        editBtn: false,
        editBtnText: this.$t('Edit'),
        editTitle: this.$t('Edit'),
        addTitle: this.$t('ADD'),
        delBtn: false,
        align: 'center',
        header: false,
        simplePage: false,
        labelWidth: "33%",
        expand: false,
        rowKey: 'Id',
        expandRowKeys: [],
        ListByCorpData: [],
        column: [
          {
            label: this.$t('Contact Name'),
            prop: 'userName',
          },
          {
            label: this.$t('Email'),
            prop: 'email',
          },
          {
            label: this.$t('Affiliation'),
            prop: 'corporationName',
          },
          {
            label: this.$t('STATE'),
            prop: 'status',
            type: 'select',
            dicData: this.YesNoData,
          }
        ]
      }
    },
  }
}
</script>

<style scoped lang="scss">
.supplierContactSettings {
  padding: 15px;

  .pvhFactory {
    padding: 15px;
    border-radius: 8px;
    background: #FFFFFF;
  }
}

.layTop {
  justify-content: space-between;
}

.d-flex {
  display: flex;
}
</style>
